import './skip-link-focus-fix';
import 'script-loader!table2csv';
import 'script-loader!jquery.marquee';
import 'script-loader!lity';

(function ($) {
	// csv exports
	$(document).ready(function() {
		$('#derp-dl').on('click', function() {
			$("#derp").table2csv({
				filename: 'wall-of-remembrance'
			});
		});
	});


$('select#remembrance-cats').on('click', function() {
  var open = $(this).data("isopen");
  if(open) {
    window.location.href = $(this).val()
  }
  //set isopen to opposite so next time when use clicked select box
  //it wont trigger this event
  $(this).data("isopen", !open);
});


	$(document).ready(function () {
		faqBlock();
		headerEffects();
		menuToggle();
		inmateLocatorMap();
		inmateStoryToggle();
		inmatePetitionButton();
		inmateFilters();
		victimFilters();
		accordionShortcode();

		$(window).scroll();
		$('.marquee').marquee();
	});

	// FAQ block
	function faqBlock() {
		$('.faq-block__heading').on('click', function(e) {
			e.preventDefault();

			var faq     = $(this).closest('.faq-block__faq'),
			    content = faq.find('.faq-block__content');
				
			content.slideToggle(300);
			faq.toggleClass('faq-block__faq--open');

			$('.faq-block__content').not(content).slideUp(300);
			$('.faq-block__faq').not(faq).removeClass('faq-block__faq--open');
		});
	}

	// Header scroll effects
	function headerEffects() {
		var navbar = $('#masthead');

		$(window).scroll(function () {
			var scrollPosition = $(window).scrollTop();

			if (scrollPosition > 100)
				navbar.addClass('site-header--scrolled');
			else
				navbar.removeClass('site-header--scrolled');
		});
	}

	// Header menu toggle
	function menuToggle() {
		$('#menu-toggle').on('click', function () {
			$(this).toggleClass('site-header__menu-toggle--open');
			$('#menu-primary-menu').toggleClass('site-header__menu--open');
		});

		$('.submenu-toggle').on('click', function () {
			var submenu = $(this).next('.sub-menu');
			$(this).toggleClass('submenu-toggle--open');
			submenu.slideToggle();
		});
	}

	// Inmate locator map
	function inmateLocatorMap() {
		var stateSelect = $('#state-select');
		if(stateSelect.length) {
			var selectStates = stateSelect.children();
			var svgStates    = $('#inmate-locator-states > *');
	
			svgStates.each(function() {
				$(this).on('mouseenter', function() {
					$(this).addClass('on');
				});
				$(this).on('mouseleave', function() {
					svgStates.each(function() {
						$(this).removeClass('on');
					});
				});
				
				$(this).on('click', function() {
					var state = $(this).attr('id'),
						url   = $('#select-' + state).val();

					if (url.length) {
						window.location = url;
					}
					return false;
				});
			});
		}
	}

	// Collapsed story toggle
	function inmateStoryToggle() {
		$('#story-toggle').on('click', function (e) {
			e.preventDefault();
			$(this).next().slideToggle();
		});
	}

	// Scroll to inmate parole petition on button press
	function inmatePetitionButton() {
		$('#petition-button').on('click', function (e) {
			e.preventDefault();

			$([document.documentElement, document.body]).animate({
				scrollTop: $("#parole-petition").offset().top - $('#masthead').outerHeight()
			}, 1000);
		});
	}

	// Inmates block category filtering
	function inmateFilters() {
		if($('#inmate-filters').length) {
			$(document).on('facetwp-refresh', function() {
				$('.inmates-block__filter-description').hide();

				if('undefined' !== typeof FWP.facets['inmate_category'] && FWP.facets['inmate_category'].length > 0) {
					var category = FWP.facets['inmate_category'];
					$('.inmates-block__filter-description[data-category="' + category + '"]').show();
					$('.inmates-block__table').removeClass('hidden');
					$('.inmates-block__wrap').hide();
				} else {
					if($('.inmates-block__wrap').length) {
						$('.inmates-block__table').addClass('hidden');
						$('.inmates-block__wrap').show();
					}
				}
			});
		}
	}

	// Victims block category filtering
	function victimFilters() {
		if($('#victim-filters').length) {
			$(document).on('facetwp-refresh', function() {
				$('.victims-block__filter-description').hide();

				if('undefined' !== typeof FWP.facets['victim_category'] && FWP.facets['victim_category'].length > 0) {
					var category = FWP.facets['victim_category'];
					$('.victims-block__filter-description[data-category="' + category + '"]').show();
					
				}
			});
		}
	}

	// Accordion shortcode
	function accordionShortcode() {
		$('.accordion-shortcode__toggle').on('click', function () {
			var accordion = $(this).parent('.accordion-shortcode'),
				content = $(this).prev('.accordion-shortcode__content');

			content.slideToggle();
			accordion.toggleClass('accordion-shortcode--open');

			if ($(this).text() == 'Close') {
				var title = $(this).data('title');
				$(this).text(title);
			} else {
				$(this).text('Close');
			}
		});
	}

})(jQuery);